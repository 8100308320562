'use client';

import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import RelatedProducts from 'components/RelatedProducts';
import RelatedRecipeComp from 'components/RelatedRecipes';
import { DictionaryService } from 'service/dictionary/dictionaryService';
import Script from 'next/script';

import type { Product, ProductCMSData } from 'types/Product';
import type { RecipeData, RecipeResult } from 'types/Recipes';
import type { ISocialMediaListComponents } from 'types/SocialSharing';
import type { IBreadcrumbTitles } from 'components/Breadcrumbs';
import type { IProductNutrition } from 'components/ProductNutrition';
import type { IPackagingOriginInfoProps } from 'components/PackagingOriginInfo';
import type { ISocialMediaComponent } from 'components/SocialMedia/types';
import { TrackingService } from 'service/tracking/trackingService';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { useTimerScrollTracking } from '@/hooks/useTimerScrollTracking';

const Breadcrumbs = dynamic(
  () => import('components/Breadcrumbs').then((m: any) => m.Breadcrumbs),
  { ssr: true },
) as any;

const ProductHeroBannerSimple = dynamic(
  () => import('components/ProductHeroBannerSimple').then((m: any) => m.ProductHeroBannerSimple),
  { ssr: true },
) as any;

const ProductNutrition = dynamic(
  () => import('components/ProductNutrition').then((m: any) => m.ProductNutrition),
  { ssr: true },
) as any;

const PackagingOriginInfo = dynamic(
  () => import('components/PackagingOriginInfo').then((m: any) => m.PackagingOriginInfo),
  { ssr: true },
) as any;

const SocialMedia = dynamic(() => import('components/SocialMedia').then((m) => m.SocialMedia), {
  ssr: true,
});

const Spacer = dynamic(() => import('components/Spacer').then((m: any) => m.Spacer), {
  ssr: true,
});

const TeaserLarge = dynamic(() => import('components/TeaserLarge').then((m) => m.TeaserLarge), {
  ssr: true,
});

const TeaserSmall = dynamic(() => import('components/TeaserSmall').then((m) => m.TeaserSmall), {
  ssr: true,
});

interface Category {
  slug: string;
  id: string;
  subBrand: string;
  keyImage: {
    asset: {
      url: string;
    }[];
  };
  productCategories: {
    products: Product[];
  }[];
}
export interface ProductCategory {
  data: {
    productCategoryPages: {
      productCategoryName: string;
      productSubcategoryPages: Category[];
      ingredientAccentColor: {
        hex: string;
      };
      backgroundIngredientImage: {
        url: string;
        mobile: string;
        desktop: string;
        width: number;
        height: number;
        altText: string;
      };
      foregroundIngredientImage: {
        url: string;
        mobile: string;
        desktop: string;
        width: number;
        height: number;
        altText: string;
      };
      productSubcategoryTitle: string;
    }[];
  };
}
export interface SubCategory {
  data: {
    productSubcategoryPages: {
      productCategories: {
        products: Product[];
      }[];
      subcategoryPageName: string;
      slug: string;
      productCategoryPage: {
        productCategoryName: string;
        slug: string;
      };
    }[];
  };
}
function ClientProductSlugPage({
  breadcrumbsCMSData,
  category,
  cmsData,
  followUsData,
  product,
  nutritionData,
  sustainabilityData,
  relatedRecipes,
  subCategory,
  subCategoryProductLength,
  subCategorySlugName,
  productDescription,
  locale,
  socialShareData,
  teasersLarge,
  teasersSmall,
}: {
  breadcrumbsCMSData: IBreadcrumbTitles;
  category: ProductCategory;
  cmsData: ProductCMSData;
  followUsData: ISocialMediaComponent;
  nutritionData: IProductNutrition;
  sustainabilityData: IPackagingOriginInfoProps;
  product: Product;
  relatedRecipes: RecipeResult[];
  recipeData: RecipeData;
  subCategory: SubCategory;
  subCategorySlugName: string;
  subCategoryProductLength: number;
  locale: string;
  productDescription: string;
  socialShareData: ISocialMediaListComponents[];
  teasersLarge: any;
  teasersSmall: any;
}) {
  useTimerScrollTracking({
    timeOut: 10 * 1000, // 10 seconds
    scrollPercentage: 25,
    eventCb: TrackingService.productPageTimeScrollPercentage,
  });
  const { pathTranslations, userToken } = useGlobalContext();
  useEffect(() => {
    TrackingService.productPage(product);
  }, []);

  useEffect(() => {
    const productSubCatData = product?.categories?.[0]?.productSubcategoryPage;
    TrackingService.contentGroups({
      cg1: 'products',
      cg5: !productSubCatData?.productCategoryPage
        ? productSubCatData?.productSubcategoryPage?.productCategoryPage?.productCategoryName || ''
        : productSubCatData?.productCategoryPage?.productCategoryName || '',
    });
  }, []);
  const dictionary = cmsData.titles.dictionaryItems;

  const relatedProductsTitles = {
    relatedProductsSubTitle:
      DictionaryService.getCopy('related_products', dictionary) || 'not there',
    relatedProductsTitle:
      DictionaryService.getCopy('related_products_title', dictionary) || 'not there',
    readMoreText: dictionary.find((item) => item.key === 'read_more') || { value: 'Read more' },
  };

  const relatedRecipesTitles = {
    seeMore: DictionaryService.getCopy('see_recipe_details', dictionary),
    relatedRecipesSubTitle: DictionaryService.getCopy('related_recipe', dictionary),
    relatedRecipesTitle: DictionaryService.getCopy('related_recipetitle', dictionary),
  };
  const recipeOverviewTitles: any = {};
  const productSubcategoryData = product?.categories?.[0]?.productSubcategoryPage;
  const isSubSubCategory = !productSubcategoryData?.productCategoryPage;
  const breadcrumbsCategories = [
    {
      slug: pathTranslations.products,
      displayName: breadcrumbsCMSData?.productsText?.value,
    },
  ];
  if (productSubcategoryData) {
    if (isSubSubCategory) {
      breadcrumbsCategories.push({
        slug: `${pathTranslations.productsCategory}${
          productSubcategoryData?.productSubcategoryPage?.productCategoryPage?.slug || ''
        }`,
        displayName:
          productSubcategoryData?.productSubcategoryPage?.productCategoryPage?.productCategoryName,
      });
      breadcrumbsCategories.push({
        slug: `${pathTranslations.productsSubCategory}${productSubcategoryData?.productSubcategoryPage?.productCategoryPage?.slug}/${productSubcategoryData?.productSubcategoryPage?.slug}`,
        displayName: productSubcategoryData?.productSubcategoryPage?.subcategoryPageName,
      });
      breadcrumbsCategories.push({
        slug: `${pathTranslations.productsSubCategory}${productSubcategoryData?.productSubcategoryPage?.productCategoryPage?.slug}/${productSubcategoryData?.productSubcategoryPage?.slug}/${productSubcategoryData?.slug}`,
        displayName: productSubcategoryData?.subcategoryPageName,
      });
    } else {
      breadcrumbsCategories.push({
        slug: `${pathTranslations.productsCategory}${
          productSubcategoryData?.productCategoryPage?.slug || ''
        }`,
        displayName: productSubcategoryData?.productCategoryPage?.productCategoryName,
      });
      breadcrumbsCategories.push({
        slug: `${pathTranslations.productsSubCategory}${productSubcategoryData?.productCategoryPage?.slug}/${productSubcategoryData?.slug}`,
        displayName: productSubcategoryData?.subcategoryPageName,
      });
    }
  }
  // Related Product
  const remainingList = 24 - subCategoryProductLength;
  const productCategory = category?.data?.productCategoryPages[0]?.productSubcategoryPages;
  const releatedProduct = subCategory?.data?.productSubcategoryPages[0]?.productCategories?.flatMap(
    (pCategory) => pCategory?.products?.filter((item) => item?.slug !== product?.slug),
  );
  const subCategoryRelatedProducts =
    productCategory?.filter((items: Category) => {
      let subCategoriesProd;
      if (items.slug !== subCategorySlugName) {
        subCategoriesProd = items;
      }
      return subCategoriesProd;
    }) || [];
  const subCategoryProducts: {}[] = [];
  subCategoryRelatedProducts.forEach((products: Category) => {
    products?.productCategories?.forEach((pCategory) => {
      pCategory?.products.forEach((productList: Product) => {
        if (subCategoryProducts.length <= remainingList) {
          subCategoryProducts.push(productList);
        }
      });
    });
  });
  const finalRelatedProducts = () => {
    let productFinalList;
    if (releatedProduct?.length <= 24) {
      productFinalList = [...releatedProduct, ...subCategoryProducts];
    } else {
      productFinalList = releatedProduct;
    }
    return productFinalList;
  };

  /**
   * Product Hero Setup
   */
  const productOnlyHero = true;
  const titlesDictionary: Record<string, string> = DictionaryService.mapDictionaryToRecords(
    cmsData.titles,
  );
  const brand = 'Dr. Oetker';
  return (
    <>
      {locale === 'nl_NL' && <Script async src="https://cdn.foodinfluencersunited.nl/p2b.js" />}
      {locale === 'fr_FR' && <Script async src="https://widget.clic2buy.com/c2b.js?locale=fr-FR" />}
      <Breadcrumbs
        titles={breadcrumbsCMSData}
        pageName={product?.productTitle}
        categories={breadcrumbsCategories}
      />
      {!product?.productImage && <Spacer />}
      {product?.productImage && (
        <ProductHeroBannerSimple
          productName={product?.productTitle}
          productImage={{ ...product.productImage, altText: `${brand} ${product.productTitle}` }}
          // Temporarily disabled - until the search page issue is fixed
          productTags={
            (product?.metaKeywords || '').indexOf(',') >= 0
              ? product?.metaKeywords?.split(',').slice(0, 6)
              : product?.metaKeywords?.split(' ').slice(0, 6)
          }
          productLabels={product?.productLabels?.slice(0, 5)} // show only the first 5 product labels
          seeAllProductLabelsTitle={DictionaryService.getCopy('see_all_product_labels', dictionary)}
          fillingQuantity={product?.fillingQuantity}
          productDescription={product?.metaDescription}
          preparationInstruction={product?.preparationInstructions}
          cookingTip={product?.cookingTips || []}
          buyNowLink={product?.productPageURL || ''}
          labels={titlesDictionary}
          productOnly={productOnlyHero}
          searchLink={`${pathTranslations.search}?category=products&filters=`}
          trackingEvents={{
            clickSocialShare: TrackingService.clickSocialShare,
            clickBuyNow: (link: string) => {
              TrackingService.clickBuyNow(link);
              TrackingService.productBuyNowButtonConversionAfterSearch(
                (window.dataLayer as Record<string, string>[]) || [],
                userToken,
              );
            },
          }}
          locale={locale}
          productGtin={product.gtin || ''}
          socialMediaListComponents={socialShareData}
          subBrandLogo={
            product.categories?.[0]?.productSubcategoryPage?.logo?.asset?.[0] || { url: '' }
          }
          nutriScore={{
            nutriScore: product?.nutriScore || '',
            title: cmsData.nutriScorePopupTitle || '',
            details: cmsData.nutriScorePopupDescription?.html || '',
            learnMore: DictionaryService.getCopy('learn_more', dictionary) || '',
          }}
        />
      )}
      {(nutritionData || productDescription || product?.ingredients) && (
        <div className="mx-auto flex flex-col items-center px-6 relative z-20">
          <ProductNutrition
            id="product"
            productDescription={productDescription}
            productDescriptionTitle={nutritionData?.descriptionTitle}
            productLabels={product?.productLabels}
            nutritionData={cmsData.isActiveNutrition && nutritionData}
            ingredients={cmsData.isActiveIngredients ? String(product?.ingredients) : ''}
            trackingEvent={TrackingService.clickProductAccordion}
          />
        </div>
      )}
      {product?.packagingInformation && (
        <PackagingOriginInfo
          superHeading={sustainabilityData?.superHeading}
          title={sustainabilityData?.title}
          ingredientsTitle={sustainabilityData?.ingredientsTitle}
          packagingTitle={sustainabilityData?.packagingTitle}
          packagingHtml={product?.packagingInformation}
          upperLeftCorner={sustainabilityData?.upperLeftCorner}
          upperRightCorner={sustainabilityData?.upperRightCorner}
          lowerLeftCorner={sustainabilityData?.lowerLeftCorner}
          lowerRightCorner={sustainabilityData?.lowerRightCorner}
        />
      )}
      {teasersLarge && <TeaserLarge teasers={teasersLarge} />}
      {releatedProduct && (
        <RelatedProducts
          products={finalRelatedProducts()}
          relatedProductsTitles={relatedProductsTitles}
        />
      )}
      {relatedRecipes?.length > 2 ? (
        <RelatedRecipeComp
          currentRecipe={relatedRecipes[0]}
          linkName={relatedRecipesTitles?.seeMore}
          relatedRecipe={relatedRecipes}
          copy={recipeOverviewTitles}
          subTitle={relatedRecipesTitles?.relatedRecipesSubTitle}
          title={relatedRecipesTitles?.relatedRecipesTitle}
        />
      ) : null}
      {teasersSmall && <TeaserSmall teasersSmall={teasersSmall} />}

      {followUsData && (
        <SocialMedia
          id={followUsData.id}
          super={followUsData.super}
          title={followUsData.title}
          socialMedia={followUsData.socialMedia}
          middlegroundImage={followUsData.middlegroundImage}
          foregroundImage={followUsData.foregroundImage}
        />
      )}
    </>
  );
}

export default ClientProductSlugPage;
