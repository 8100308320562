import { IPackagingOriginInfoProps } from 'components/PackagingOriginInfo';
import ClientProductSlugPage, {
  ProductCategory,
  SubCategory,
} from 'components/PageLevelComponents/ClientProductSlugPage';
import { IProductNutrition } from 'components/ProductNutrition';
import { Product, ProductCMSData } from 'types/Product';
import type { RecipeData, RecipeResult } from 'types/Recipes';

import type { ISocialMediaComponent } from 'components/SocialMedia/types';
import { ANONYMOUS_TOKEN_NAME, LOCALE_DEFAULT } from 'config/config';
import { ProductPageController } from 'server/controller/page/products/productPageController';
import { IProductPageResource } from 'server/resource/page/productPageResource';
import { Layout } from '@/components/Layout/Layout';
import { GetServerSideProps } from 'next';

const RootPage = (props: IProductPageResource) => {
  const {
    metaData,
    product,
    nutritionData,
    breadcrumbsCMSData,
    footerNavData,
    footerImage,
    usercentricsIds,
    locale,
    searchOverlayData,
    countrySelectorData,
    newsletterData,
    contactUsUrl,
    difficultyCopy,
    getHeaderNav,
    getMobileNavData,
    popularSearchData,
    cardCarouselCMSData,
    recallData,
    productDescription,
    sustainabilityData,
    cmsData,
    recipeData,
    followUsData,
    socialShareData,
    subCategory,
    category,
    subCategorySlugName,
    subCategoryProductLength,
    teasersLarge,
    relatedRecipes,
    teasersSmall,
    pathTranslations,
    userToken,
  } = props;

  return (
    <Layout
      themeName={undefined}
      pathTranslations={pathTranslations}
      locale={locale}
      globalMetadata={metaData}
      getHeaderNav={getHeaderNav}
      getMobileNavData={getMobileNavData}
      popularSearchData={popularSearchData}
      footerNavData={footerNavData}
      footerImage={footerImage}
      usercentricsIds={usercentricsIds}
      searchOverlayData={searchOverlayData}
      countrySelectorData={countrySelectorData}
      newsletterData={newsletterData}
      contactUsUrl={contactUsUrl}
      difficultyCopy={difficultyCopy as Record<string, string>}
      cardCarouselCMSData={cardCarouselCMSData}
      recallData={recallData}
      userToken={userToken}
    >
      <ClientProductSlugPage
        product={product as Product}
        nutritionData={nutritionData as unknown as IProductNutrition}
        productDescription={productDescription as string}
        sustainabilityData={sustainabilityData as IPackagingOriginInfoProps}
        cmsData={cmsData as ProductCMSData}
        recipeData={recipeData as unknown as RecipeData}
        locale={locale}
        followUsData={followUsData as unknown as ISocialMediaComponent}
        socialShareData={socialShareData}
        subCategory={subCategory as unknown as SubCategory}
        category={category as unknown as ProductCategory}
        subCategorySlugName={subCategorySlugName as string}
        subCategoryProductLength={subCategoryProductLength as number}
        relatedRecipes={relatedRecipes as RecipeResult[]}
        breadcrumbsCMSData={breadcrumbsCMSData}
        teasersLarge={teasersLarge}
        teasersSmall={teasersSmall}
      />
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  params,
  defaultLocale,
  resolvedUrl,
  req,
}) => {
  const anonymousToken = req.cookies[ANONYMOUS_TOKEN_NAME];
  return ProductPageController.getCachedProductPageData({
    resolvedUrl,
    locale: locale || defaultLocale || LOCALE_DEFAULT,
    slug: Array.isArray(params?.slug) ? params.slug.join('/') : params?.slug ?? '',
    userToken: anonymousToken ?? '',
  });
};

export default RootPage;
